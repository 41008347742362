// Gatsby supports TypeScript natively!
import React, { useState } from 'react';
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CEO from "../components/ceo"
import Head from "../components/head"

import Form from "../components/form"
import Textfield from "../components/textfield"
import Content from '../components/content';

const Demo = (props: PageProps) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmitting, submitting] = useState(false);
  const [isSend, send] = useState(false);

  const submitForm = async e => {

    let formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);

    e.preventDefault();
    submitting(true)
    fetch("https://api.syndic-en-un-clic.fr/demo-request", {
      method: "POST",
      body: formData
    }).then(function(response) {
      console.log(response)
      if (response.ok) {
        send(true)
      }
      submitting(false)
    })
  };

  return (
  <Layout color='secondary'>
    <SEO title="Prendre un rendez-vous" />
    <Head title='RENDEZ-VOUS EN LIGNE'>
      Aurélie vous contacte pour convenir<br/>  d’un rendez-vous
    </Head>
    <CEO/>
    {isSend?(
      <Content>
        <p>Merci {name}, votre demande de rendez-vous est envoyée.</p>
      </Content>
    ):(
    <Form submit={'DEMANDER UN RDV'} onSubmit={submitForm} isSubmitting={isSubmitting} >
      <Textfield id="name" label="Nom & Prénom" value={name} onChange={setName}/>
      <Textfield id="phone" label="Téléphone" value={phone} onChange={setPhone}/>
    </Form>
    )}
  </Layout>
)}

export default Demo
